<template>
  <div class="about-container">
    <div class="about-card">
      <img src="../assets/eom-logo.png" alt="Organization Logo" class="about-logo" />
      <div class="about-info">
        <h1>创始人</h1>
        <p>Jackson Yeung、Zachery Cheung</p>
        <h1>愿景</h1>
        <p>打造易用的开源软件/组件。</p>
        <h1>组织使命</h1>
        <p>打造易用的开源软件/组件。</p>
        <h1>组织愿景</h1>
        <p>为了初学者少走歪路，以MIT宽松开源协议为基础，致力于帮助初学者更快地使用相应技术和源码。</p>
        <h1>组织介绍</h1>
        <p>易开元(Easy-Open-Meta)创建于2020年3月7日，前身为咖啡与网络(Java-and-Net)，是由一群Java、.Net等开发者建立的开源组织。</p>
        <h1>发展历程</h1>
        <el-timeline>
          <el-timeline-item
            v-for="event in timeline"
            :key="event.year"
            :timestamp="event.year"
            placement="top"
          >
            {{ event.description }}
          </el-timeline-item>
        </el-timeline>
        <h1>欢迎加入我们</h1>
        <p>一、发送加入邮件到:eom-official#oscode.top(#替换成@)</p>
        <p>二、组织主页加入，<a href="https://gitee.com/java-and-net">点击此处</a>跳转到组织主页，点击【加入组织】按钮和【关注】按钮！</p>
        <p>三、捐献项目加入，发送邮件及项目附件到:eom-official#oscode.top(#替换成@)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      timeline: [
        { year: '2019/09/30', description: '发布第一款项目TCODE开源论坛。' },
        { year: '2020/03/07', description: '咖啡与网络开源组织与Gitee代码托管平台建立。' },
        { year: '2020/04/09', description: 'TS 酒店管理系统发布' },
        { year: '2021/09/15', description: '第一批组织成员加入，从2个增长到19个。' },
        { year: '2022/09/01', description: '咖啡与网络(Java-and-Net)开源组织正式更名为易开元(Easy Open Meta)。' },
        { year: '2022/12/05', description: '易开元(Easy Open Meta)正式入驻GitHub。' }
      ]
    };
  }
};
</script>

<style>
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.about-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
}

.about-logo {
  max-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto 20px;
}

.about-info h1 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.about-info p {
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1.6;
}

.el-timeline {
  text-align: left;
  margin-top: 20px;
}

.el-timeline-item {
  margin: 10px 0;
}
</style>