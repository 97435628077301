<template>
    <div class="manage-news">
      <el-row justify="space-between" class="toolbar">
        <el-button type="primary" @click="addNews">新增新闻</el-button>
      </el-row>
  
      <el-table :data="newsList" style="width: 100%">
        <el-table-column prop="id" label="新闻ID" width="100" />
        <el-table-column prop="title" label="标题" />
        <el-table-column prop="summary" label="摘要" />
        <el-table-column prop="url" label="链接" />
        <el-table-column prop="createdTime" label="创建时间">
          <template #default="scope">
            {{ formatCreatedTime(scope.row.createdTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button size="small" @click="editNews(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteNews(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <el-dialog :title="isEditing ? '编辑新闻' : '新增新闻'" v-model="dialogVisible">
        <el-form :model="currentNews" label-width="120px" ref="newsForm">
          <el-form-item label="标题" prop="title" :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]" >
            <el-input v-model="currentNews.title" placeholder="请输入新闻标题" />
          </el-form-item>
          <el-form-item label="摘要" prop="summary">
            <el-input v-model="currentNews.summary" type="textarea"  placeholder="请输入摘要" />
          </el-form-item>
          <el-form-item label="链接" prop="url">
            <el-input v-model="currentNews.url"  placeholder="请输入链接"/>
          </el-form-item>
          <el-form-item label="发表日期" prop="url">
            <el-date-picker v-model="currentNews.createdTime" type="date" placeholder="请选择发表日期" :size="size" />
          </el-form-item>
        </el-form>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveNews">保存</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import axios from '@/api/index';
  import moment from 'moment-timezone';
  
  export default {
    name: 'ManageNews',
    data() {
      return {
        newsList: [],
        dialogVisible: false,
        currentNews: {},
        isEditing: false,
        isLoading: true,
      };
    },
    methods: {
      fetchNews() {
        this.isLoading = true;
        axios.get('/News/GetNewss')
          .then((response) => {
            this.newsList = response;
          })
          .catch((error) => {
            this.$showError(`加载失败: ${error.message}`);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      addNews() {
        this.isEditing = false;
        this.currentNews = { title: '', summary: '', url: '', createdTime: moment().tz('Asia/Shanghai').format() };
        this.dialogVisible = true;
      },
      editNews(news) {
        this.isEditing = true;
        news.createdTime = moment(news.createdTime).tz('Asia/Shanghai').format();
        this.currentNews = { ...news };
        this.dialogVisible = true;
      },
      saveNews() {
        const url = this.isEditing ? '/News/UpdateNews' : '/News/InsertNews';
        this.currentNews.createdTime = moment(this.currentNews.createdTime).tz('Asia/Shanghai').format();
        axios.post(url, this.currentNews)
          .then(() => {
            this.dialogVisible = false;
            this.fetchNews();
          })
          .catch((error) => {
            this.$showError(`保存失败: ${error.message}`);
          });
      },
      deleteNews(news) {
        this.$confirm(`确定要删除新闻 "${news.title}" 吗？`, '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            axios.delete(`/News/DeleteNews/${news.id}`)
              .then(() => {
                this.fetchNews();
              })
              .catch((error) => {
                this.$showError(`删除失败: ${error.message}`);
              });
          })
          .catch(() => {
            this.$showWarning('删除已取消');
          });
      },
      formatCreatedTime(dateString) {
        const date = moment(dateString).tz('Asia/Shanghai');
        return date.format('YYYY年MM月DD日');
      },
    },
    mounted() {
      this.fetchNews();
    },
  };
  </script>
  
  <style scoped>
  .toolbar {
    margin-bottom: 20px;
  }
  </style>
  