<template>
  <div class="manage-members">
    <el-row justify="space-between" class="toolbar">
      <el-button type="primary" @click="addMember">新增成员</el-button>
    </el-row>

    <div v-if="isLoading">
      <p>正在加载成员列表...</p>
    </div>

    <el-table v-else :data="paginatedMembers" style="width: 100%">
      <el-table-column prop="id" label="成员ID" width="100" />
      <el-table-column label="头像">
        <template #default="scope">
          <img :src="scope.row.avatar" alt="头像" style="width: 40px; height: 40px; object-fit: cover;" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="level" label="职务" />
      <el-table-column prop="language" label="擅长语言" />
      <el-table-column prop="direction" label="技术方向" />
      <el-table-column prop="giteeUrl" label="Gitee主页" />
      <el-table-column prop="githubUrl" label="Github主页" />
      <el-table-column prop="homeUrl" label="个人网站" />
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button size="mini" @click="editMember(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteMember(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="members.length > 0"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="members.length"
      layout="total, prev, pager, next, jumper"
      style="margin-top: 20px; text-align: right;"
    />

    <el-dialog :title="isEditing ? '编辑成员' : '新增成员'" v-model="dialogVisible">
      <el-form :model="currentMember" ref="memberForm" label-width="120px">
        <el-form-item label="头像" prop="avatar">
          <el-input v-model="currentMember.avatar" placeholder="请输入头像链接" />
        </el-form-item>
        <el-form-item label="姓名" prop="name" :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
          <el-input v-model="currentMember.name" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="职务" prop="level">
          <el-input v-model="currentMember.level" placeholder="请输入职务" />
        </el-form-item>
        <el-form-item label="擅长语言" prop="language">
          <el-input v-model="currentMember.language" placeholder="请输入擅长语言" />
        </el-form-item>
        <el-form-item label="技术方向" prop="direction">
          <el-input v-model="currentMember.direction" placeholder="请输入技术方向" />
        </el-form-item>
        <el-form-item label="Gitee主页" prop="giteeUrl">
          <el-input v-model="currentMember.giteeUrl" placeholder="请输入 Gitee 链接" />
        </el-form-item>
        <el-form-item label="Github主页" prop="githubUrl">
          <el-input v-model="currentMember.githubUrl" placeholder="请输入 Github 链接" />
        </el-form-item>
        <el-form-item label="个人网站" prop="homeUrl">
          <el-input v-model="currentMember.homeUrl" placeholder="请输入个人网站链接" />
        </el-form-item>
      </el-form>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveMember">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from '@/api/index';

export default {
  name: "ManageMembersView",
  data() {
    return {
      members: [],
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      currentMember: {},
      isEditing: false,
      isLoading: true,
    };
  },
  computed: {
    paginatedMembers() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.members.slice(start, end);
    }
  },
  methods: {
    fetchMembers() {
      this.isLoading = true;
      axios.get('/Member/GetMembers')
        .then(response => {
          this.members = response;
        })
        .catch(error => {
          this.$showError(`Error: ${error.message}`);
          this.members = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    addMember() {
      this.isEditing = false;
      this.currentMember = {
        avatar: '',
        name: '',
        level: '',
        language: '',
        direction: '',
        giteeUrl: '',
        githubUrl: '',
        homeUrl: ''
      };
      this.dialogVisible = true;
    },
    editMember(member) {
      this.isEditing = true;
      this.currentMember = { ...member };
      this.dialogVisible = true;
    },
    saveMember() {
      const url = this.isEditing ? '/Member/UpdateMember' : '/Member/InsertMember';

      axios.post(url, this.currentMember)
        .then(() => {
          this.dialogVisible = false;
          this.fetchMembers();
        })
        .catch(error => {
          this.$showError(`Error: ${error.message}`);
        });
    },
    deleteMember(member) {
      this.$confirm(`确定要删除成员 "${member.name}" 吗?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        axios.delete(`/Member/DeleteMember/${member.id}`)
          .then(() => {
            this.fetchMembers();
          })
          .catch(error => {
            this.$showError(`Error: ${error.message}`);
          });
      })
      .catch(() => {
        this.$showWarning(`已取消删除`);
      });
    },
  },
  mounted() {
    this.fetchMembers();
  }
};
</script>

<style scoped>
.toolbar {
  margin-bottom: 20px;
}
</style>
