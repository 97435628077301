<template>
    <div class="manage-committee">
      <el-row justify="space-between" class="toolbar">
        <el-button type="primary" @click="addMember">新增技术委员</el-button>
      </el-row>
  
      <el-table :data="committeeMembers" style="width: 100%">
        <el-table-column prop="id" label="技术委员ID" width="80" />
        <el-table-column label="头像">
            <template #default="scope">
            <img :src="scope.row.avatar" alt="头像" style="width: 40px; height: 40px; object-fit: cover;" />
            </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" />
        <el-table-column prop="level" label="职务" />
        <el-table-column prop="language" label="擅长语言" />
        <el-table-column prop="direction" label="技术方向" />
        <el-table-column prop="giteeUrl" label="Gitee" />
        <el-table-column prop="githubUrl" label="GitHub" />
        <el-table-column prop="homeUrl" label="个人网站" />
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button size="small" @click="editMember(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteMember(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <el-dialog :title="isEditing ? '编辑技术委员' : '新增技术委员'" v-model="dialogVisible">
        <el-form :model="currentMember" label-width="120px" ref="memberForm">
          <el-form-item label="头像" prop="avatar">
            <el-input v-model="currentMember.avatar" placeholder="请输入头像链接" />
          </el-form-item>
          <el-form-item label="姓名" prop="name" :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
            <el-input v-model="currentMember.name" placeholder="请输入姓名" />
          </el-form-item>
          <el-form-item label="职务" prop="level">
            <el-input v-model="currentMember.level" placeholder="请输入职务" />
          </el-form-item>
          <el-form-item label="擅长语言" prop="language">
            <el-input v-model="currentMember.language" placeholder="请输入擅长语言" />
          </el-form-item>
          <el-form-item label="技术方向" prop="direction">
            <el-input v-model="currentMember.direction" placeholder="请输入技术方向" />
          </el-form-item>
          <el-form-item label="Gitee" prop="giteeUrl">
            <el-input v-model="currentMember.giteeUrl" placeholder="请输入Gitee链接" />
          </el-form-item>
          <el-form-item label="GitHub" prop="githubUrl">
            <el-input v-model="currentMember.githubUrl" placeholder="请输入GitHub链接" />
          </el-form-item>
          <el-form-item label="个人网站" prop="homeUrl">
            <el-input v-model="currentMember.homeUrl" placeholder="请输入个人网站链接" />
          </el-form-item>
        </el-form>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveMember">保存</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import axios from '@/api/index';
  
  export default {
    name: 'ManageTechnicalCommitee',
    data() {
      return {
        committeeMembers: [],
        dialogVisible: false,
        currentMember: {},
        isEditing: false,
        isLoading: true,
      };
    },
    methods: {
      fetchCommiteeMembers() {
        axios.get('/Commitee/GetCommittees')
          .then(response => {
            this.committeeMembers = response;
          })
          .catch(error => {
            console.error(`加载失败: ${error.message}`);
          });
      },
      addMember() {
        this.isEditing = false;
        this.currentMember = {
          avatar: '',
          name: '',
          level: '',
          language: '',
          direction: '',
          giteeUrl: '',
          githubUrl: '',
          homeUrl: ''
        };
        this.dialogVisible = true;
      },
      editMember(member) {
        this.isEditing = true;
        this.currentMember = { ...member };
        this.dialogVisible = true;
      },
      saveMember() {
        const url = this.isEditing ? '/Commitee/UpdateCommittee' : '/Commitee/InsertCommittee';
        axios.post(url, this.currentMember)
          .then(() => {
            this.dialogVisible = false;
            this.fetchCommiteeMembers();
          })
          .catch(error => {
            console.error(`保存失败: ${error.message}`);
          });
      },
      deleteMember(member) {
        this.$confirm(`确定删除技术委员 "${member.name}" 吗？`, '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            axios.delete(`/Commitee/DeleteCommittee/${member.id}`)
              .then(() => {
                this.fetchCommiteeMembers();
              })
              .catch(error => {
                console.error(`删除失败: ${error.message}`);
              });
          })
          .catch(() => {
            console.log('删除已取消');
          });
      },
    },
    mounted() {
      this.fetchCommiteeMembers();
    },
  };
  </script>
  
  <style scoped>
  .toolbar {
    margin-bottom: 20px;
  }
  </style>
  