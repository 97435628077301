import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';

import '@fortawesome/fontawesome-free/css/all.css';

import { showError, showWarning, showSuccess } from '@/utils/errorHandler.js';

import EventBus from '@/utils/eventBus';

const app = createApp(App);

app.config.globalProperties.$showError = showError;
app.config.globalProperties.$showWarning = showWarning;
app.config.globalProperties.$showSuccess = showSuccess;

app.provide('eventBus', EventBus); 

app.use(router);
app.use(ElementPlus, {
  locale: zhCn,
});

app.mount('#app');