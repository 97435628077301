import { createRouter, createWebHistory } from 'vue-router';
import BaseLayout from '@/components/BaseLayout.vue';
import News from '@/views/NewsView.vue';
import ProjectsList from '@/views/ProjectsListView.vue';
import ProjectDetail from '@/views/ProjectDetailView.vue';
import MembersList from '@/views/MemberView.vue';
import TechnicalCommittee from '@/views/TechnicalCommitteeView.vue';
import AboutView from '@/views/AboutView.vue';

import LoginView from '@/views/admin/LoginView.vue';
import AdminLayout from '@/components/AdminLayout.vue';
import Dashboard from '@/views/admin/DashboardView.vue';
import ManageNews from '@/views/admin/ManageNewsView.vue';
import ManageProjects from '@/views/admin/ManageProjectsView.vue';
import ManageMembers from '@/views/admin/ManageMembersView.vue';
import ManageCommittees from '@/views/admin/ManageTechnicalCommittee.vue';

const routes = [
  {
    path: '/',
    component: BaseLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: News,
        meta: { title: '首页 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'project-list.html',
        name: 'project-list',
        component: ProjectsList,
        meta: { title: '项目一览 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: '/project/:id.html',
        name: 'ProjectDetail',
        component: ProjectDetail,
        meta: { title: '项目详情 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'member-list.html',
        name: 'members',
        component: MembersList,
        meta: { title: '成员列表 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'committee-list.html',
        name: 'committee',
        component: TechnicalCommittee,
        meta: { title: '技术委员会 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'about.html',
        name: 'about',
        component: AboutView,
        meta: { title: '关于我们 - 易开元(Easy-Open-Meta)' }
      }
    ]
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: '',
        name: 'index',
        component: Dashboard,
        meta: { title: '仪表盘 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'dashboard.html',
        name: 'admin-dashboard',
        component: Dashboard,
        meta: { title: '仪表盘 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'manage-news.html',
        name: 'manage-news',
        component: ManageNews,
        meta: { title: '新闻动态管理 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'manage-projects.html',
        name: 'manage-projects',
        component: ManageProjects,
        meta: { title: '项目管理 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'manage-members.html',
        name: 'manage-members',
        component: ManageMembers,
        meta: { title: '成员管理 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'manage-committees.html',
        name: 'manage-committees',
        component: ManageCommittees,
        meta: { title: '技术委员管理 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'login.html',
        name: 'admin-login',
        component: LoginView,
        meta: { title: '登录 - 易开元(Easy-Open-Meta)' }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('token');
  document.title = to.meta.title || '易开元(Easy-Open-Meta)';
  
  if (to.path.startsWith('/admin')) {
    if (!isLoggedIn) {
      if (to.name === 'admin-login') {
        next();
      } else {
        next({ name: 'admin-login' });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
export { routes };
