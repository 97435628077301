export function parseJwt(token) {
    if (!token) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join('')
    );
  
    return JSON.parse(jsonPayload);
  }
  
  export function isTokenExpired(token) {
    const decoded = parseJwt(token);
    if (!decoded || !decoded.exp) {
      return true;
    }
  
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTime;
  }
  
  export function monitorToken() {
    const token = localStorage.getItem('token');
    if (!token) return;
  
    const decoded = parseJwt(token);
    if (decoded && decoded.exp) {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeout = (decoded.exp - currentTime) * 1000;
  
      if (timeout > 0) {
        setTimeout(() => {
          this.$showWarning('Token 已过期');
          localStorage.removeItem('token');
        }, timeout);
      }
    }
  }
  