<template>
  <div class="manage-projects">
    <el-row justify="space-between" class="toolbar">
      <el-button type="primary" @click="addProject">新增项目</el-button>
    </el-row>

    <div v-if="isLoading">
      <p>Loading projects...</p>
    </div>

    <el-table v-else :data="paginatedProjects" style="width: 100%">
      <el-table-column prop="id" label="项目ID" width="100" />
      <el-table-column prop="name" label="项目名称" />
      <el-table-column prop="description" label="项目描述" />
      
      <el-table-column label="创建日期">
        <template #default="scope">
          {{ formatCreatedTime(scope.row.creationDate) }}
        </template>
      </el-table-column>

      <el-table-column prop="repoUrl" label="Gitee仓库地址" />
      <el-table-column prop="gitHubRepoUrl" label="GitHub仓库地址" />
      <el-table-column prop="maintainer" label="维护者" />
      
      <el-table-column label="项目图标">
        <template #default="scope">
          <img :src="scope.row.logo" alt="项目图标" style="width: 40px; height: 40px; object-fit: cover;" />
        </template>
      </el-table-column>

      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button size="small" @click.stop="editProject(scope.row)">编辑</el-button>
          <el-button size="small" type="danger" @click.stop="deleteProject(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="projects.length > 0"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="projects.length"
      layout="total, prev, pager, next, jumper"
      style="margin-top: 20px; text-align: right;"
    />

    <el-dialog :title="isEditing ? '编辑项目' : '新增项目'" v-model="dialogVisible">
      <el-form :model="currentProject" ref="projectForm">
        <el-form-item label="项目名称" prop="name" :rules="[{ required: true, message: '请输入项目名称', trigger: 'blur' }]">
          <el-input v-model="currentProject.name" placeholder="请输入项目名称" />
        </el-form-item>
        <el-form-item label="项目描述" prop="description">
          <el-input v-model="currentProject.description" placeholder="请输入项目描述"/>
        </el-form-item>
        <el-form-item label="Gitee仓库地址" prop="repoUrl">
          <el-input v-model="currentProject.repoUrl" placeholder="请输入Gitee仓库地址"/>
        </el-form-item>
        <el-form-item label="GitHub仓库地址" prop="gitHubRepoUrl">
          <el-input v-model="currentProject.gitHubRepoUrl" placeholder="请输入GitHub仓库地址"/>
        </el-form-item>
        <el-form-item label="维护者" prop="maintainer">
          <el-input v-model="currentProject.maintainer" placeholder="请输入维护者"/>
        </el-form-item>
        <el-form-item label="项目图标" prop="logo">
          <el-input v-model="currentProject.logo" placeholder="请输入项目图标"/>
        </el-form-item>
        <el-form-item label="立项日期" prop="url">
          <el-date-picker v-model="currentProject.creationDate" type="date" placeholder="请选择立项日期" :size="size" />
        </el-form-item>
      </el-form>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveProject">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from '@/api/index';
import moment from 'moment-timezone';

export default {
  name: "ManageProjectsView",
  data() {
    return {
      projects: [],
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      currentProject: {},
      isEditing: false,
      isLoading: true,
    };
  },
  computed: {
    paginatedProjects() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.projects.slice(start, end);
    }
  },
  methods: {
    formatCreatedTime(dateString) {
        const date = moment(dateString).tz('Asia/Shanghai');
        return date.format('YYYY年MM月DD日');
      },
    fetchProjects() {
      this.isLoading = true;
      axios.get('/Project/GetProjects')
        .then(response => {
          this.projects = response;
        })
        .catch(error => {
          this.$showError(`Error: ${error.message}`);
          this.projects = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    addProject() {
      this.isEditing = false;
      this.currentProject = {
        name: '',
        description: '',
        repoUrl: '',
        gitHubRepoUrl: '',
        maintainer: '',
        logo: '',
        creationDate: moment().tz('Asia/Shanghai').format()
      };
      this.dialogVisible = true;
    },
    editProject(project) {
      this.isEditing = true;
      project.creationDate = moment(project.creationDate).tz('Asia/Shanghai').format();
      this.currentProject = { ...project };
      this.dialogVisible = true;
    },
    saveProject() {
      const url = this.isEditing ? '/Project/UpdateProject' : '/Project/InsertProject';
      this.currentProject.creationDate = moment(this.currentProject.creationDate).tz('Asia/Shanghai').format();
      axios.post(url, this.currentProject)
        .then(() => {
          this.dialogVisible = false;
          this.fetchProjects();
        })
        .catch(error => {
          this.$showError(`Error: ${error.message}`);
        });
    },
    deleteProject(project) {
      this.$confirm(`确定要删除项目 "${project.name}" 吗?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        axios.delete(`/Project/DeleteProject/${project.id}`)
          .then(() => {
            this.fetchProjects();
          })
          .catch(error => {
            this.$showError(`Error: ${error.message}`);
          });
      })
      .catch(() => {
        this.$showWarning(`已取消删除`);
      });
    },
  },
  mounted() {
    this.fetchProjects();
  }
};
</script>

<style scoped>
.toolbar {
  margin-bottom: 20px;
}
</style>
