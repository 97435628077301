<template>
  <el-container style="min-height: 100vh; border: 1px solid #eee;">
    <el-header style="background-color: white; color: black; display: flex; align-items: center; justify-content: space-between; padding: 20px 20px; height: 63px;">
      <a href="/" style="display: flex; align-items: center; color: inherit; text-decoration: none;">
        <img src="../assets/eom-logo.png" alt="Logo" style="height: 40px;" />
        <span style="margin-left: 10px;">易开元(Easy-Open-Meta) - 后台管理</span>
      </a>

      <el-menu :default-active="$route.name" mode="horizontal" class="custom-menu" style="flex: 7; justify-content: flex-end;">
        <el-menu-item index="home">
          <router-link to="/" style="text-decoration: none; color: inherit;">官网主页</router-link>
        </el-menu-item>
      </el-menu>
      <div v-if="isLoggedIn" style="margin-left: 20px; color: inherit;">Account</div>
      <el-button v-else @click="goToLogin" type="primary" style="margin-left: 20px;">账号登录/注册</el-button>
    </el-header>
    
    <el-container>
      <!-- 左侧导航栏 -->
      <el-aside width="200px" style="background-color: #f5f5f5;">
        <el-menu>
          <el-menu-item index="admin-dashboard">
            <router-link to="/admin/dashboard.html" style="text-decoration: none; color: inherit;">仪表盘</router-link>
          </el-menu-item>
          <el-menu-item index="manage-news">
            <router-link to="/admin/manage-news.html" style="text-decoration: none; color: inherit;">新闻动态管理</router-link>
          </el-menu-item>
          <el-menu-item index="manage-projects">
            <router-link to="/admin/manage-projects.html" style="text-decoration: none; color: inherit;">项目管理</router-link>
          </el-menu-item>
          <el-menu-item index="manage-members">
            <router-link to="/admin/manage-members.html" style="text-decoration: none; color: inherit;">成员管理</router-link>
          </el-menu-item>
          <el-menu-item index="manage-committees">
            <router-link to="/admin/manage-committees.html" style="text-decoration: none; color: inherit;">技术委员管理</router-link>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-main style="padding: 40px; background-color: #f3f4f5;">
        <router-view></router-view>
      </el-main>
    </el-container>

    <el-footer style="text-align: center; line-height: 60px; height: 60px;">
      Developed by <a href="/">易开元(Easy Open Meta)</a> based on <a href="https://element-plus.org/">Element Plus</a>
    </el-footer>
  </el-container>
</template>

<script>
import { inject,ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  setup() {
    const eventBus = inject('eventBus');
    const isLoggedIn = ref(localStorage.getItem('token') !== null);

    const syncLoginStatus = (status) => {
      isLoggedIn.value = status;
    };

    onMounted(() => {
      eventBus.on('loginStatusChanged', syncLoginStatus);
    });

    onBeforeUnmount(() => {
      eventBus.off('loginStatusChanged', syncLoginStatus);
    });

    return {
      isLoggedIn,
      goToLogin() {
        this.$router.push({ name: 'login' });
      },
    };
  },
};
</script>