// utils/messageHandler.js
import { ElMessage } from 'element-plus';

/**
 * 全局消息提示
 * @param {string} message - 消息内容
 * @param {string} type - 消息类型，可选值：success、warning、info、error
 * @param {number} duration - 显示时间，默认 3000 毫秒
 */
export function showMessage(message, type = 'info', duration = 3000) {
  ElMessage({
    message: message || '发生了未知情况',
    type: type,
    duration: duration,
    showClose: true, // 显示关闭按钮
  });
}

/**
 * 快捷函数：全局错误提示
 * @param {string} message - 错误信息
 */
export function showError(message) {
  showMessage(message, 'error');
}

/**
 * 快捷函数：全局警告提示
 * @param {string} message - 警告信息
 */
export function showWarning(message) {
  showMessage(message, 'warning');
}

/**
 * 快捷函数：全局成功提示
 * @param {string} message - 成功信息
 */
export function showSuccess(message) {
  showMessage(message, 'success');
}
