<template>
  <div class="dashboard">
    <el-card class="dashboard-card" shadow="hover">
      <h2>欢迎来到管理后台</h2>
      <p>这里是你的仪表盘，可查看系统统计信息。</p>
    </el-card>

    <el-row :gutter="20" class="dashboard-stats">
      <el-col :span="6">
        <el-card shadow="hover">
          <h3>新闻动态数</h3>
          <p>{{ stats.news }}</p>
        </el-card>
      </el-col>
      <el-col :span="6" >
        <el-card shadow="hover">
          <h3>项目数</h3>
          <p>{{ stats.projects }}</p>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="hover">
          <h3>组织成员数</h3>
          <p>{{ stats.members }}</p>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="hover">
          <h3>技术委员数</h3>
          <p>{{ stats.commitees }}</p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from '@/api/index';

export default {
  name: "DashboardView",
  data() {
    return {
      stats: {
        news: 0,
        projects: 0,
        members: 0,
        commitees: 0,
      },
    };
  },
  mounted() {
    this.fetchNews();
    this.fetchProjects();
    this.fetchMembers();
    this.fetchCommitees();
  },
  methods: {
    async fetchNews() {
      try {
        const response = await axios.get('/News/GetNewsCount');
        this.stats.news = response;
      } catch (error) {
        console.error("Failed to fetch newss:", error);
        this.$message.error('新闻数据加载失败');
      }
    },
    async fetchProjects() {
      try {
        const response = await axios.get('/Project/GetProjectCount');
        this.stats.projects = response;
      } catch (error) {
        console.error("Failed to fetch projects:", error);
        this.$message.error('项目数据加载失败');
      }
    },
    async fetchMembers() {
      try {
        const response = await axios.get('/Member/GetMemberCount');
        this.stats.members = response;
      } catch (error) {
        console.error("Failed to fetch members:", error);
        this.$message.error('成员数据加载失败');
      }
    },
    async fetchCommitees() {
      try {
        const response = await axios.get('/Commitee/GetCommitteeCount');
        this.stats.commitees = response;
      } catch (error) {
        console.error("Failed to fetch commitees:", error);
        this.$message.error('技术委员数据加载失败');
      }
    },
  },
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
}

.dashboard-card {
  margin-bottom: 20px;
}

.dashboard-stats .el-card {
  text-align: center;
}
</style>
