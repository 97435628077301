<!-- src/views/LoginView.vue -->
<template>
    <div class="login">
      <el-form :model="form" ref="formRef" label-width="100px">
        <el-form-item label="用户名" prop="Account" :rules="[{ required: true, message: '请输入用户名', trigger: 'blur' }]">
          <el-input v-model="form.Account" />
        </el-form-item>
  
        <el-form-item label="密码" prop="Password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]">
          <el-input type="password" v-model="form.Password" />
        </el-form-item>
  
        <el-form-item>
          <el-button type="primary" @click="handleLogin">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  import { inject } from 'vue';
  import axios from 'axios';
  import SHA256 from 'crypto-js/sha256';
  
  export default {
    setup() {
    const eventBus = inject('eventBus');
    return { eventBus };
    },
    data() {
      return {
        form: {
          Account: '',
          Password: ''
        }
      };
    },
    methods: {
      async handleLogin() {
        try {
          const hashedPassword = SHA256(this.form.Password).toString();
          const response = await axios.post('/api/Login/Login', {
            Account: this.form.Account,
            Password: hashedPassword
          });
  
          this.$showSuccess('登录成功');
          localStorage.setItem('token', response.data.token);
          this.eventBus.emit('loginStatusChanged', true);
          this.$router.push({ name: 'index' });
        } catch (error) {
          this.$showError('登录失败');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .login {
    max-width: 400px;
    margin: auto;
  }
  </style>
  